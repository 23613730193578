/* SCSS Variables */

//font for continental
$continentalstagsans-book-webfont: 'continentalstagsans-book-webfont', Arial;
$continentalstagsans-light-webfont: 'continentalstagsans-light-webfont', Arial;
$continentalstagsans-med-webfont: 'continentalstagsans-med-webfont', Arial;


/** continental colors ***/
$white-color: #fff;
$black-color: #000;
$continental-yellow-color : #ffa500;
$carousel-inactive-color : #8d8d8d;
$continental-text-color : #222222;
$continental-body-color: #eaeaea;
$continental-border-color: #cccccc;
$continental-truck-border-color: #f0f0f0;
$continental-bread-crumb-color: #969696;
$continental-red-color: #FF2D37;
$continental-blue-color: #7983ea;
$payload-color: #2db928;
$payload-remaining-color: #c3c3c3;
$table-header-bg-color: #f7f7f7;
$light-black-color:#1a1a1a;
$input-error-color:#8d8d8d;
$profile-heading: #707070;
$error-color:#f44336;
$dark-black-color: #333333;
$green-color: #008000;
$light-grey-color: #9b9b9b;
$menu-border: #f0f0f0;
$continental-chart-link: #00A5DC;
$device-count:#DC8F00;
$chart-title-color: #666666;
$device-dashboard-color: #999999;
$table-block-color: #333333;

