/* You can add global styles to this file, and also import other style files */
body{
    margin: unset !important;
}
app-home {
    .p-galleria .p-galleria-caption{
        background-color: rgb(0 0 0 / 0%) !important;
    }
    .p-galleria .p-galleria-item-nav{
        z-index: 1 !important;
    }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~flag-icon-css/css/flag-icon.min.css';

@import 'variables';
@import 'all';
@import 'font';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
