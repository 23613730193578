@import './variables';

/*Global Styles*/
html,
body {
  height: 100%;
  background-color: $white-color;
}

ul,
ul li {
  list-style: none;
}

*:focus {
  outline: none;
}

body {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: $continentalstagsans-book-webfont !important;
  // color: $module-fill-color;
  padding: 0px;
  margin: 0px;
  margin: 0 auto;
}


.carousel-indicators {
  justify-content: left;
  margin-left: 8%;
}

.carousel-indicators > li.active {
  background-color:$continental-yellow-color;
}

.carousel-indicators > li {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color:$carousel-inactive-color;
  opacity: 0.6;
}

a {
  cursor: pointer !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt70 {
  padding-top: 70px !important;
}

/** to hide background color for api spinner **/

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 75%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
  color: #fff;
  opacity: 0.5;
}

.loader-wrapper {
  .modal-content {
    background: none !important;
    border: none !important;
  }
}

.modal-dialog.modal-sm {
  max-width: 384px !important;
  top:16%;
  .modal-close-block {
    height: 20px;
  }

  .modal-body {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px !important;

    .modal-body-message {
      position: relative;
      display: flex;
      height: 100%;
      min-height: 460px;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
  } 
  
  .modal-footer {
    border-radius: 0 0 5px 5px;
    justify-content: center !important;
    background-color: $black-color;
  }
  
  .modal-footer.hide {
    display: none;
  }
  
  .modal-close {
    background-image: url('./assets/images/icon-i-close.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    right: 16px;
    top:12px
  }
  .mat-check-box {
    position: relative;
    .checkbox-error {
      position: absolute;
      top:25px
    }
  }
}

/*** fixing height issue ***/
.element-row {
  max-height: 62px;
}

.device-management {   
  .mat-icon-button {
    background-color: $continental-yellow-color;
    border-radius: 50%;
  }
  .mat-paginator-navigation-previous {
    margin-right: 10px;
  }
  .mat-button-disabled {
    opacity: 0.5;
  }
}

/*** menu drop down ***/
.mat-menu-panel {
  margin-top: 25px!important;
  left: 44px!important;
  position: relative;
}

.custom-menu + .cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane:after {
    padding:10px !important;
    content: '' !important;
    width: 24px;
    height: 24px;
    background: url('./assets/images/triangle.png');
    position: relative;
    top:3px;
  }
}

button.mat-menu-item {
  border-bottom: 1px solid $menu-border;
  margin:0 10px;
  width: 90% !important;
}

#navbarCollapse {
  .mat-select {
    .mat-select-value,
    .mat-select-value-text,  
    .mat-select-arrow {
        color: $white-color !important;
    }
  }
}

.mat-paginator-container {
  justify-content: center !important;
}

.mat-paginator-page-size-label,
.mat-paginator-range-label,
.mat-paginator-page-size-value {
  display: none;
}

.mat-mini-fab {
  box-shadow: none !important;
}


.mat-checkbox.mat-accent {
  .mat-checkbox-frame {
    border: 1px solid $light-black-color !important;
    border-radius: 3px !important;
  }

  &.mat-checkbox-checked .mat-checkbox-background, .mat-ripple-element {
    background-color: $white-color !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: $continental-yellow-color !important;
    stroke-width: 3px;
    border: 1px solid $light-black-color !important;
  }

  .mat-custom-class .mat-ripple-element, .mat-checkbox-checkmark,
  .mat-checkbox-indeterminate.mat-accent 
    .mat-checkbox-background {
    border: 1px solid $light-black-color !important;
    border-radius: 3px !important;
  }
}

.device-compare {
  .mat-form-field-infix {
    border: 2px solid $continental-yellow-color !important;
    font-weight: bold;
    padding-left: 5px;
  }
}

/*** modal window for charts ***/

.modal-dialog.modal-md {
  max-width: 960px !important;
  top:2%;

  .chart {
    margin: 0 !important;
  }

  .modal-body {
    padding-top:44px;
    .mat-form-field-underline {
      display: none;
    }
    .head {
      .mat-form-field-infix {
        border: 1px solid #000;
        padding-left: 10px;
      }
    }
    .mat-form-field-wrapper {
      padding-bottom: 5px !important;
    }
  }

  .tab-content>.tab-pane {
    height: 1px;
    overflow: hidden;
    display: block;
   visibility: hidden;
  }
  .tab-content>.active {
    height: auto;
    overflow: hidden;
    visibility: visible;
  }
  
  .modal-close {
    background-image: url('./assets/images/icon-i-close.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    right: 16px;
    top:12px
  }
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  border-bottom: 1px solid $continental-bread-crumb-color;
}

/*** modal window for date picker ***/

.modal-dialog.modal-smd {
  max-width: 600px !important;
  top:2%;

  .modal-body {     
    .nav-tabs {
      border-bottom: none !important;
    }
    .ngb-dp-months {
      height: 260px !important;
    }
    .date-block {
      padding-left: 66px;
    }
  }
  
  .modal-close {
    background-image: url('./assets/images/icon-i-close.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    right: 16px;
    top:12px
  }
}


/*** modal window for add/edit device ***/

.modal-dialog.modal-amd {
  max-width: 750px !important;
  top:12%;

  .modal-body {
    padding-top:44px;
    img {
      height: 22px !important;
      background-size: 100% 100%;
    }
    .mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
      height: 40px !important;
      margin-bottom: 4px !important;
    }
    .cdk-global-overlay-wrapper, .cdk-overlay-container {
      z-index: 99999 !important;
    }
    
    .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
      border-bottom: 1px solid $continental-bread-crumb-color;
    }
  }
  
  .modal-close {
    background-image: url('./assets/images/icon-i-close.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    right: 16px;
    top:12px
  }
}

/*** device management to hide installation date search box ***/

#search_createdDate {
  display: none !important;
}

.ngx-table {
  thead {
    tr {
      background-color: $table-header-bg-color;
      th {
        color: $input-error-color;
        font-size: 14px; 
        font-family: $continentalstagsans-light-webfont;
      }
      th:first-child {
        padding-left: 40px;
      } 
    }
  }
  tbody {
    tr {
      td {
        color:$black-color;
        font-size: 14px; 
        font-family: $continentalstagsans-light-webfont;
      }
    }
  }
}

.ngx-pagination-wrapper {
  text-align: center;
  margin-top: 20px !important;
  .current {
    background-color: $continental-yellow-color;
    color: $black-color;
  }
}

/**** for charts page duration selection ***/

.duration {
  .mat-form-field-label {
      margin-top: 5px;
  }
}

/*** settings page **/
.settings-block {
  .mat-form-field-wrapper {
    max-width: 70%;
  }
  .mat-slide-toggle-content {
    font-size:15px;
    margin-top:3px;
  }
  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-thumb {
      background-color:$continental-yellow-color
    }
    .mat-slide-toggle-bar {
      background-color:$continental-border-color;
    }
  }
  .mat-slide-toggle-thumb {
    background-color:$continental-yellow-color
  }
  .mat-slide-toggle-bar {
    background-color:$continental-border-color;
  }
}

/*** hiding drop downs in pagination ***/

.ngx-pagination-range-dropdown-button {
  display: none !important;
}

@media (min-width:576px) {
  .navbar-expand-sm .navbar-collapse {
    justify-content: right;
  }
  .dropdown-item {
    padding: 0.25rem 1rem;
  }

  .post-login {
    .navbar {
      padding: 0 40px;
      height: 80px;
    }
    .user-name {
      right: 15px;
    }
    .lang-nav {
      position: relative;
      right: 15px;
    }
    .language {
      width: 50px;
      position: relative;
      bottom: 65px;
    }
    .mat-select-arrow-wrapper {
      position: relative;
      right: 8px;
    }
  }
}
@media (max-width:420px) {
  .post-login {
    .navbar {
      padding: 0 20px 10px;
    }
    .navbar-brand {
      position: relative;
      left: 100px;
    }
    .navbar-toggler {
      position: absolute;
      top:10px;
      left:5px;
    }
    .navbar-toggler:focus {
      outline: none !important;
    }
    .language {
      width: 50px;
      position: relative;
      bottom:63px;
    }
    .lang-nav {
      position: relative;
      top: 8px;
    }
    .mat-select-arrow-wrapper {
      position: relative;
      right: 2px;
    }
    .carousel-indicators {
      justify-content: center !important;
    }
  }
}

.menu {   
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none !important;
  }
}