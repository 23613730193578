
  /* Font Styles Start */
  @font-face {
    font-family: 'continentalstagsans-book-webfont';
    src: url('/assets/fonts/continentalstagsans-book-webfont.ttf') format('truetype');
  }
  @font-face {
    font-family: 'continentalstagsans-light-webfont';
    src: url('/assets/fonts/continentalstagsans-light-webfont.ttf') format('truetype');
  }
  @font-face {
    font-family: 'continentalstagsans-med-webfont';
    src: url('/assets/fonts/continentalstagsans-med-webfont.ttf') format('truetype');
  }
  